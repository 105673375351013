<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.name"
        placeholder="请输入姓名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <Category
        :isRequired="true"
        :value="listQuery.category"
        @valueFunc="getQueryValueFunc"
        widthStyle="200px"
        type="category"
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="编号" width="60"> </el-table-column>
      <el-table-column prop="name" label="名字" width="80"> </el-table-column>
      <el-table-column prop="categorys" label="分类" width="180">
      </el-table-column>
      <el-table-column prop="stage_name" label="艺名"></el-table-column>
      <el-table-column prop="gender" label="性别"></el-table-column>
      <el-table-column prop="birthday" label="出生日期"></el-table-column>
      <el-table-column prop="deathDate" label="逝世日期"></el-table-column>
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column prop="appellation" label="称谓"></el-table-column>
      <el-table-column prop="factions" label="派系"></el-table-column>
      <el-table-column
        prop="introduction"
        label="简称"
        width="480"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <Category
            :isRequired="true"
            :value="form.category"
            @valueFunc="getValueFunc"
            type="category"
          />
        </el-form-item>
        <el-form-item label="艺名" prop="stageName">
          <el-input v-model="form.stageName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select
            v-model="form.gender"
            placeholder="请选择"
            style="width:100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in GENDER"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="form.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="逝世日期" prop="deathDate">
          <el-date-picker
            v-model="form.deathDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="国籍" prop="country">
          <el-input v-model="form.country" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="称谓" prop="appellation">
          <el-input v-model="form.appellation" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="派系" prop="factions">
          <el-input v-model="form.factions" placeholder="请输入" />
        </el-form-item>

        <el-form-item label="简介" prop="introduction">
          <el-input
            placeholder="请输入"
            type="textarea"
            v-model="form.introduction"
            :autosize="{ minRows: 2, maxRows: 8 }"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false, {}, '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="saveAddOrEditData"
          :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GENDER } from "@/utils/enumUtile";
import { formatApiDate } from "@/utils/utils";
import { artistsGetList, artistsCreate, artistsUpdate } from "@/api/art";
import Category from "@/views/components/category.vue";
import moment from "moment";

export default {
  computed: {
    GENDER() {
      return GENDER;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: { Category },
  filters: {},
  data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        name: "",
        category: "", // 分类
        page: 1,
        limit: 10
      },
      dialogFormVisible: false, // 新增 or 编辑的弹框
      rules: {
        introduction: [
          { required: true, message: "简介不能为空", trigger: "change" }
        ],
        name: [{ required: true, message: "姓名不能为空", trigger: "change" }]
      },
      form: {
        id: 0, // 用作与修改
        name: "", // 姓名
        stageName: "", // 艺名
        gender: "", // 性别
        birthday: "", // 出生年月日
        deathDate: "", // 逝世日期
        country: "", // 国籍
        appellation: "", // 称谓
        factions: "", // 派系
        introduction: "", // 简介
        category: "" // 分类
      },
      btnLoading: false
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      artistsGetList(this.listQuery)
        .then(response => {
          this.list = (response.data && response.data.data) || [];
          this.total = response.data.total || [];
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      if (status) {
        this.form = {
          ...editObj,
          stageName: editObj.stage_name,
          deathDate: editObj.death_date
        };
      }
      if (!status) {
        this.form = {
          id: 0, // 用作与修改
          name: "", // 姓名
          stageName: "", // 艺名
          gender: "", // 性别
          birthday: "", // 出生年月日
          deathDate: "", // 逝世日期
          country: "", // 国籍
          appellation: "", // 称谓
          factions: "", // 派系
          introduction: "", // 简介
          category: "" // 分类
        };
        this.$refs.form.resetFields();
      }
      this.dialogFormVisible = status;
    },
    /**
     * 确认添加还是编辑
     */
    saveAddOrEditData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const params = {
            ...form
          };
          if (form.birthday) {
            params.birthday = moment(form.birthday).format("YYYY-MM-DD");
          }
          if (form.deathDate) {
            params.deathDate = moment(form.deathDate).format("YYYY-MM-DD");
          }
          this.btnLoading = true;
          if (!params.id || params.id === 0) {
            delete params.id;
            artistsCreate(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "保存成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
          if (params.id && params.id !== 0) {
            artistsUpdate(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "保存成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
        }
      });
    },
    /**
     * 分类的值
     * @param type
     * @param resultArr
     */
    getValueFunc(type, resultArr) {
      if (type === "category") {
        this.form.category = JSON.stringify(resultArr);
      }
    },
    /**
     * 查询条件分类的值
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      if (type === "category") {
        this.listQuery.category = resultArr.join("");
      }
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
